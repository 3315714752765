import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

function getPath({ path, parent }) {
  if (parent?.[0]?.path) {
    return `/${parent[0].path}/${path}`
  }
  return path ? `/${path}` : "/"
}

export default ({ title, path, parent, image }) =>
  image ? (
    <Link to={getPath({ path, parent })} id="grid-story">
      <div className="grid-content">
        {image && (
          <Img
            fluid={image.image.childImageSharp.fluid}
            className="grid-image"
          />
        )}
        <div className="grid-title">{title}</div>
      </div>
    </Link>
  ) : (
    <Link
      to={getPath({ path, parent })}
      id="grid-page"
      style={{ textDecoration: "inherit" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: "32px",
        }}
      >
        <div>{title}</div>
      </div>
    </Link>
  )
