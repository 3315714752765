import React from "react"

import ProductGrid from "./ProductGrid"
import StoryGrid from "./StoryGrid"
import GalleryGrid from "./GalleryGrid"
import PageGrid from "./PageGrid"
import ImageGrid from "./ImageGrid"
import SocialMediaGrid from "./SocialMediaGrid"

export default ({ item, schema }) => {

  if (schema === "_social_media") {
    return <SocialMediaGrid {...item} />
  }

  if (!item) return <div>Not existing or not visible</div>

  switch (schema) {
    case "product":
      return <ProductGrid {...item} />
    case "story":
      return <StoryGrid {...item} />
    case "gallery":
      return <GalleryGrid {...item} />
    case "page":
      return <PageGrid {...item} />
    case "_image":
      return <ImageGrid {...item} />
    case "_social_media":
      return <SocialMediaGrid {...item} />
    default:
      return <div>Not defined Grid</div>
  }
}
