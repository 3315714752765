import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default ({ item }) => {
  const data = useStaticQuery(graphql`
    query {
      socialMedia: file(
        relativePath: {
          eq: "2020-067-LEB-Website-2.Quartal-SM Ankündigung Startseit 1.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const getFields = (
    evt,
    instagram = () => {},
    facebook = () => {},
    other = () => {}
  ) => {
    const { clientX: x, clientY: y, target } = evt
    const imgRect = target.getBoundingClientRect()
    const { left, top, width, height } = imgRect
    const marginTop = 0.4 * height

    // isInside?
    if (
      left <= x &&
      x <= left + width &&
      top + marginTop <= y &&
      y <= top + height
    ) {
      if (x - left < width / 2) {
        // left instagram
        instagram()
      } else if (x - left < width) {
        // right facebook
        facebook()
      }
    } else {
      other()
    }
  }

  return (
    <div
      id="grid-social-media"
      role="link"
      onMouseMove={evt => {
        const { target } = evt
        const setPointer = () => (target.style.cursor = "pointer")
        const setAuto = () => (target.style.cursor = "auto")
        getFields(evt, setPointer, setPointer, setAuto)
      }}
      onClick={evt => {
        const linkTo = link => () => window.open(link)
        getFields(
          evt,
          linkTo("https://www.instagram.com/lebenskreis_apotheke/"),
          linkTo(
            "https://de-de.facebook.com/pages/category/Pharmacy---Drugstore/Lebenskreis-Apotheke-Zeltweg-247943495847837/"
          )
        )
      }}
    >
      <Img fluid={data.socialMedia.childImageSharp.fluid} />
    </div>
  )
}
